.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p{
  font-size: 15pt;
  font-family: 'Lato', sans-serif;

}

h1,h2,h3,h4,h5,h6{
font-family: 'Special Elite', cursive;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}


.flip-box {
  background-color: transparent;
  width: 500px;
  height: 500px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px;
  margin: 20px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  width: 500px;
  border-radius: 25px;
  transform: rotateY(180deg);
}

.main-image {
  width: 500px;
  border-radius: 25px;
}

/* When in phone mode */

@media(max-width: 525px){
.flip-box {
  background-color: transparent;
  width: 300px;
  height: 300px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px;
  margin: 50px;
}

.flip-box-back {
  width: 300px;
  border-radius: 25px;
  transform: rotateY(180deg);
}

.main-image {
  width: 300px;
  border-radius: 25px;
}

p {
  font-size: 10pt;
  font-family: 'Lato', sans-serif;

}
}
